//accordion dropdown
class DropDown extends HTMLElement {
  constructor() {
    super();
    this.bindEvents();
  }

  bindEvents(){
    this.querySelector('.dropdown-trigger').addEventListener('click', this.toggle.bind(this))
  }

  toggle(){
    let dropdownTrigger = this.querySelector('.dropdown-trigger');

    if (dropdownTrigger.classList.contains('open')) {
        dropdownTrigger.classList.remove('open');
    } else if (dropdownTrigger.classList.contains('open-desktop') && window.innerWidth > 1024) {
        dropdownTrigger.classList.remove('open-desktop');
    } else {
        dropdownTrigger.classList.add('open');
    }
      let ariaExpanded = this.getAttribute('aria-expanded');
      if (ariaExpanded === "true") {
        this.setAttribute('aria-expanded', "false");
      } else {
        this.setAttribute('aria-expanded', "true");
      }
  }
}

customElements.define('drop-down', DropDown);
